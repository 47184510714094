body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root {
  --color-black: #0c0c0c;
  --color-yellow: #ffd006;
  --color-yellow-bg: #ffd10611;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.img-cus {
  width: 40%;
  max-height: auto;
  object-fit: cover;
  object-position: top;
}

.bg {
  background: repeating-linear-gradient(
      90deg,
      #c0c0c2 0,
      #c0c0c2 5%,
      transparent 0,
      transparent 50%
    ),
    repeating-linear-gradient(
      180deg,
      #c0c0c2 0,
      #c0c0c2 5%,
      transparent 0,
      transparent 50%
    );
  background-size: 5em 5em;
  background-color: transparent;
  opacity: 1;
}

@media (max-width: 800px) {
  .img-cus {
    width: 100% !important;
    max-height: auto !important;
    object-fit: cover;
    object-position: top;
  }
}
