.hover:hover {
  background: #ffd10611 !important;
  color: var(--color-yellow) !important;
}
#bg 
{	
	position:fixed;
  height: 100vh;
width: 100vw;
	background: cover;
  background: linear-gradient(145deg, #192839 50%, #1ab4e2 115%);
	margin: 0;
  padding: 0;
  z-index: -2;
}
.btn-Hover {
  transition: 0.3s;
}
.btn-Hover:hover {
  border: 2px solid var(--color-yellow);
  color: var(--color-yellow);
}
.textMaxLineOne {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.MuiButton-endIcon {
  margin-left: 0 !important;
}
.d-flex {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
#box::-webkit-scrollbar {
  display: none;
}

.cube {
  position: absolute;
  width: 150px;
  height: 150px;
}
.move {
  animation: move 0.8s ease-in-out infinite alternate both;
}
.moves {
  animation: move 2s ease-in-out infinite alternate both;
}
.blink {
  width: 14;
  height: 14;
  color: #fff;
  position: absolute;
  animation: blink 8s ease-in-out infinite alternate both;
}

.bg-dots {
  background: radial-gradient(circle, #ffd1062c 22%, transparent 50%),
    radial-gradient(circle at bottom left, #ffd1062c 10%, transparent 13%),
    radial-gradient(circle at bottom right, #ffd1062c 12%, transparent 13%),
    radial-gradient(circle at top left, #ffd1062c 12%, transparent 13%),
    radial-gradient(circle at top right, #ffd1062c 12%, transparent 13%);
  background-size: 60em 60em;
  background-color: #ffffff;
}

@keyframes blink {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    opacity: 0.2;
    transform: scale(0.5);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes move {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(7px);
  }
}
.mainDiv {
  position: relative;
}
.square {
  width: 95px;
  height: 80px;
  background: #eeeeee1c;
  border: solid 2px #ffffff75;
  transform: skew(180deg, 210deg);
  position: absolute;
  top: 43px;
  left: 20px;
  backdrop-filter: blur(50px) !important;
}
.square2 {
  width: 85px;
  height: 80px;
  background: transparent;
  border: solid 2px #ffffff75;
  transform: skew(180deg, 150deg);
  position: absolute;
  left: 122px;
  top: 48px;
}
.square3 {
  width: 99px;
  height: 100px;
  background: transparent;
  border: solid 2px #ffffff75;

  transform: rotate(150deg) translate(-40px, -16px) skew(30deg, 0deg);
  position: absolute;

  left: 20px;
  top: -32px;
}

@media (max-width: 1200px) {
  .cube {
    display: none;
  }
}
